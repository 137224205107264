/* eslint-disable */
import dynamic from 'next/dynamic';


import GoogleTagManagerNoscript from '@magalu/mixer-modules/build/esm/novisual/GoogleTagManagerNoscript';
import HeaderDesktop from '@magalu/mixer-modules/build/esm/components/ecommerce/HeaderDesktop';
import PromoterHeader from '@magalu/mixer-modules/build/esm/components/ecommerce/PromoterHeader';
import CarouselBanners from '@magalu/mixer-modules/build/esm/components/ecommerce/CarouselBanners';
import PopularDepartments from '@magalu/mixer-modules/build/esm/components/ecommerce/PopularDepartments';
import FooterDesktopPMD from '@magalu/mixer-modules/build/esm/components/ecommerce/FooterDesktopPMD';
import Favicon from '@magalu/mixer-modules/build/esm/novisual/Favicon';
import Preconnect from '@magalu/mixer-modules/build/esm/novisual/Preconnect';
import GoogleTagManagerHead from '@magalu/mixer-modules/build/esm/novisual/GoogleTagManagerHead';
import Stewie from '@magalu/mixer-modules/build/esm/novisual/Stewie';
import AllIn from '@magalu/mixer-modules/build/esm/novisual/AllIn';
import Criteo from '@magalu/mixer-modules/build/esm/novisual/Criteo';
import Radware from '@magalu/mixer-modules/build/esm/novisual/Radware';
import AllInNewsletter from '@magalu/mixer-modules/build/esm/novisual/AllInNewsletter';

const pageModules = {
  GoogleTagManagerNoscript,
  HeaderDesktop,
  PromoterHeader,
  CarouselBanners,
  PopularDepartments,
  FooterDesktopPMD,
  Favicon,
  Preconnect,
  GoogleTagManagerHead,
  Stewie,
  AllIn,
  Criteo,
  Radware,
  AllInNewsletter,
  ZipCodeInput: dynamic(() => import('@magalu/mixer-modules/build/esm/components/ecommerce/ZipCodeInput'),
    { ssr: false }
  ),
  Showcase: dynamic(() => import('@magalu/mixer-modules/build/esm/components/ecommerce/Showcase'),
    { ssr: false }
  ),
  Loader: dynamic(() => import('@magalu/mixer-modules/build/esm/components/ecommerce/Loader'),
    { ssr: false }
  ),
};

export default pageModules;
