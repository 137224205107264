/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
/* eslint-disable import/no-unresolved */
import dynamic from 'next/dynamic';
import ErrorModule from 'next/error';

const Custom404 = dynamic(() => import('../containers/notfound').catch(() => null));

const withCustomError = (Page, CustomErrorPage = Custom404) => props => {
  const { structure = {}, errorCode, errorTitle } = props;

  if (errorCode) return <ErrorModule statusCode={errorCode} title={errorTitle} />;

  if (CustomErrorPage && structure.id && structure.id === '404')
    return <CustomErrorPage {...props} />;

  return <Page {...props} />;
};

export default withCustomError;
